<template>
<nav class="bg-gray-900">
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white " aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>

          <svg class="h-6 w-6" v-if="!hamburger" @click.prevent="toggleHamburger" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg class="h-6 w-6" v-if="hamburger" @click.prevent="toggleHamburger"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <a href="https://www.rocketbusd.app"><img class="h-12 w-auto" src="@/assets/RocketLogoBanner.png" alt="Workflow"></a>
        </div>
        <div class="hidden sm:flex sm:ml-6 items-center">
          <div class="flex space-x-4">
            <a href="https://www.rocketbusd.app/" class="text-gray-300 hover:bg-black-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
            <a href="https://www.rocketbusd.app/buy/" class="text-gray-300 hover:bg-black-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Buy Now</a>
            <a href="https://docs.rocketbusd.app/" target="_blank" class="text-gray-300 hover:bg-black-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Docs</a>
            <a href="https://www.rocketbusd.app/shop/" class="text-gray-300 hover:bg-black-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Shop</a>
            <a href="https://twitter.com/RocketBUSD" target="_blank" class="text-gray-300 hover:bg-black-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" id="mobile-menu" v-if="hamburger">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a href="https://www.rocketbusd.app/" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</a>
      <a href="https://www.rocketbusd.app/buy/" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Buy Now</a>
      <a href="https://docs.rocketbusd.app/" target="_blank" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Documentation</a>
      <a href="https://www.rocketbusd.app/about/"  class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">About Us</a>
      <a href="https://www.rocketbusd.app/faq/" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">FAQ</a>
      <a href="https://www.rocketbusd.app/shop/" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Shop</a>
      <a href="https://twitter.com/RocketBUSD" target="_blank" class="text-gray-300 hover:bg-black-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Twitter</a>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  data() {
    return {
      hamburger: false,
    }
  },
  methods: {
    toggleHamburger() {
      this.hamburger = !this.hamburger
    }
  }
}
</script>

<style scoped>

</style>
